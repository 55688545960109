import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

const colors = {
    cream: '#fceecb',
    coffee: 'rgba(207, 161, 115, 0.9)',
    coffeeWithMilk: 'rgba(207, 161, 115, 0.4)',
    caramel: '#b84e19',
    creamCloud: 'rgba(252, 238, 203, 0.5)',
    caramelCloud: 'rgba(184, 78, 25, 0.25)',
    caramelCloudIntense: 'rgba(184, 78, 25, 0.5)',
}

const theme = {
    colors,
}

const muiTheme = createTheme(
    {
        palette: {
            primary: {
                main: colors.caramel,
            },
            secondary: {
                main: colors.cream,
            },
            error: {
                main: red.A400,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: colors.cream
                    }
                }
            },
            // Name of the component
            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        backgroundColor: colors.caramel,
                    },
                },
            },
        },
    }
)

export {theme, muiTheme}
