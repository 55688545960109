import React, {useReducer} from "react"

const initialState = {en: false, nl: false, ru: false, services: null, events: null, info: null}
export const GlobalStateContext = React.createContext(initialState)
export const GlobalDispatchContext = React.createContext({})

function reducer(state, action) {
    switch (action.type) {
        case 'SET_EN_SEEN': {
            return {
                ...state,
                en: true,
            }
        }
        case 'SET_NL_SEEN': {
            return {
                ...state,
                nl: true,
            }
        }
        case 'SET_RU_SEEN': {
            return {
                ...state,
                ru: true,
            }
        }
        case 'SET_SERVICES': {
            return {
                ...state,
                services: action.value,
            }
        }
        case 'SET_EVENTS': {
            return {
                ...state,
                events: action.value,
            }
        }
        case 'SET_INFO': {
            return {
                ...state,
                info: action.value,
            }
        }
        default:
            throw new Error('Bad Action Type')
    }
}

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState, undefined)
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider